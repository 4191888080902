import axios from "axios";
import { baseApiUrl } from "src/utils/baseurl";

const api = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': "application/json",
  },
  withCredentials: true
});

export default api;