import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationPT from './pt_BR/translation.json'
import moment from 'moment'


// the translations
const resources = {
  pt_BR: {
    translation: translationPT
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language,
  fallbackLng: 'pt_BR',
  interpolation: {
    escapeValue: false,
    format: function(value, format, lng) {      
      if(value instanceof Date) return value ? moment(value).format(format): "";
      return value;
    }
  }
})
export default i18n