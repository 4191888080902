import { createStore, combineReducers } from 'redux';
import Cookies from 'js-cookie';
import api from "src/services/api";
import legacyFetcher from "src/services/legacyEndpoints";

const authInitialState = {
  customerToken: localStorage.getItem('customer_token') || "",
  customerName: localStorage.getItem('customer_name') || "",
  customerPermissions: localStorage.getItem('customer_permissions') || "",
  customerCode: localStorage.getItem('customer_code') || "",
  customerCpfCnpj: localStorage.getItem('customer_cpfCnpj') || "",
  user: localStorage.getItem('user') || {},
}

const authReducer = (state = authInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest };
    case 'customerLogin':
      localStorage.setItem("customer_token",rest.customerToken)
      localStorage.setItem("customer_name",rest.customerName)
      localStorage.setItem("customer_permissions",rest.customerPermissions)
      localStorage.setItem("customer_code",rest.customerCode)
      localStorage.setItem("customer_cpfCnpj",rest.customerCpfCnpj)
      return {...state, customerToken: rest.customerToken, customerName: rest.customerName, customerPermissions: rest.customerPermissions.toString() || "", customerCode: rest.customerCode, customerCpfCnpj: rest.customerCpfCnpj};
    case 'customerLogout':
      localStorage.removeItem("customer_token")
      localStorage.removeItem("customer_name")
      localStorage.removeItem("customer_permissions")
      localStorage.removeItem("customer_code")
      localStorage.removeItem("customer_cpfCnpj")
      return {...state, customerToken: ""};
    case 'userLogin':
      localStorage.setItem("user",rest.user)
      Cookies.set("csrftoken", rest.csrftoken)
      api.defaults.headers.common["X-CSRFToken"] = rest.csrftoken
      legacyFetcher.defaults.headers.common["X-CSRFToken"] = rest.csrftoken
      return {...state, user: rest.user};
    case 'userLogout':
      localStorage.removeItem("user")
      return {...state, user: {}};
    default:
      return state
  }
}

const themeInitialState = {
  sidebarShow: 'responsive'
}

const themeReducer = (state = themeInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest };
    default:
      return state
  }
}

const reducers = {
  theme: themeReducer,
  auth: authReducer,
}

const reducer = combineReducers(reducers)
const store = createStore(reducer)
export default store
