import axios from "axios";
import { baseURL } from "src/utils/baseurl";
import Cookies from 'js-cookie';

const legacyFetcher = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': "application/json",
  },
  withCredentials: true
});

export default legacyFetcher;