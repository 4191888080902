
import React, {useState} from 'react';
import {createContext} from 'use-context-selector';


export const FormContext = createContext({data: {}});

export const FormProvider = ({children}) => {
  const [data, setData] = useState({});
  const [submitting, setSubmitting] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [errors, setErrors] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [inlinesToDelete, setInlinesToDelete] = useState([])

  return (
    <FormContext.Provider
      value={{
        data,
        setData,
        submitting, 
        setSubmitting,
        loadingData,
        setLoadingData,
        errors,
        setErrors,
        showDeleteModal, 
        setShowDeleteModal,
        inlinesToDelete,
        setInlinesToDelete
      }}>
      {children}
    </FormContext.Provider>
  );
};

