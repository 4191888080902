import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store/store.js'
import { registerLocale } from 'react-datepicker';
import ptBR from "date-fns/locale/pt-BR";
registerLocale("pt-BR", ptBR);

React.icons = icons
document.documentElement.lang = "pt"
const appRoot = document.getElementById('root')
appRoot.setAttribute('notranslate', true)

ReactDOM.render(
  <Provider store={store}>
      <App/>
  </Provider>,
  appRoot
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
